.header-settings {
  align-self: center !important;
  flex: 1;
  text-align: left;
  margin-right: 10px;
}

.settings-popover-wrap {
  padding: 20px 20px 20px 5px;
}

.settings-row {
  display: flex;
  .settings-labels {
    flex: 1;
    color: #3c4043;
  }
  .settings-values {
    flex: 4;
    display: flex;
    .settings-col-option {
      display: flex;
      align-items: flex-start;
    }
  }
}

.display-box {
  border: 2px solid #d2d0ce;
  height: 80px;
  width: 100px;
  padding: 10px;
  cursor: pointer;
  &.selected {
    border: 2px solid #4ac2d0;
    background-color: #ebf8f9;
  }
  .display-row-grid {
    height: 5px;
    background-color: #abaaa8;
    margin-bottom: 5px;
  }
  .display-list-wrap {
    display: flex;
    height: 100%;
    .display-list {
      flex: 1;
      margin-right: 5px;
      .display-row-list {
        width: 100%;
        height: 7px;
        background-color: #abaaa8;
        margin-bottom: 5px;
      }
    }
    .display-details {
      flex: 2;
      background-color: #abaaa8;
    }
  }
}
