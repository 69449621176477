.header{
    margin-bottom: -22px;
    background-color: rgba(255, 255, 255, 0.96);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    min-height: 70px;
    display: flex;
    padding: 10px 20px 10px 20px;;
    .title {
        color: #0f4a53;
        align-self: center;
        font-weight: 500;
        font-size: 20px;
        text-transform: uppercase;
    }
    .header-nav-root {
        position: absolute;
        right: 50px;
        bottom: 0;
        .header-nav-tab {
            width: 70px;
            min-width: 70px;
        }
    }
    .sub-title{
        align-self: center;
        color: rgba(0, 0, 0, 0.54);
        margin-left: 0.4rem;
        &.medium {
            font-weight: 500;
        }
    }
    .sub-title-icon {
        align-self: center;
        margin-left: 3rem;
        font-size: 1.2rem;
        color: rgba(0, 0, 0, 0.44);
        &.large {
            font-size: 1.3rem;
        }
    }
}
