.MuiInputAdornment-root {
    .sm-textfield-icon {
      padding: 4px !important;
      margin-right: -7px !important;
      display: none;
      svg {
        font-size: 1.25rem;
      }
    }
}

.sm-outlined-input-small {
    &.show-icon-always {
        .sm-textfield-icon {
            display: inline-flex;
        }
    }
    &.show-icon-on-hover {
        .sm-textfield-icon {
            display: none;
        }
    }
    &.show-icon-on-hover:hover {
        .sm-textfield-icon {
            display: inline-flex;
        }
    }
}

.sm-input-warning {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    svg {
        margin-right: 5px;
        color: #ff9800;
    }
    span {
        line-height: 22px;
        color: #e08602;
    }
}

.sm-icon-button-small-wrap {
    margin-left: .5rem;
    &~.sm-icon-button-small-wrap {
        margin-left: 0px;
    }
}