@import "variables";

.k-i-add:before, .k-i-plus:before, .k-plus:before {
    content: "\E014";
}
.k-i-kpi-trend-equal:before, .k-i-minus:before, .k-minus:before {
    content: "\E015";
}

::-webkit-scrollbar {
    width: 10px!important;
    height: 10px!important;
    cursor: pointer!important;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .1)!important;
}

.main-container {
    position: relative;
    top: 0;
    height: 100vh;
    min-height: 100vh;
    .main-panel {
        max-height: 100%;
        height: 100%;
        transition-property: top, bottom, width;
        transition-duration: 0.2s, 0.2s, 0.35s;
        transition-timing-function: linear, linear, ease;
        -webkit-overflow-scrolling: touch;
        position: relative;
        float: right;
        width: calc(100% - 260px);
        min-height: 100%;
    }
}

.with-right-panel {
    .content {
        width: calc(100% - 310px);
    }
    .right-panel {
        width: 310px;
    }
    &.large {
        .content {
            width: 100%;
        }
        .right-panel {
            width: 710px;
            box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
            border-left: 0px;
        }
    }
}

.content {
    padding: 15px 0px;
    height: calc(100vh - 70px);
    width: 105%;
    transition-property: top, bottom, width;
    transition-duration: 0.2s, 0.2s, 0.35s;
    transition-timing-function: linear, linear, ease;
    -webkit-overflow-scrolling: touch;
    position: relative;
    .right {
        margin-left: 15px;
    }
    .card-header {
        background-color: #fff;
    }
    .card.full-height {
        height: calc(100vh - 100px);
    }
    .card-container {
        height: 100%;
    }
    .card-content {
        &.with-buttons {
            &.no-buttons {
                height: calc(100% - 65px);
            }
            overflow-y: auto;
            height: calc(100% - 141px);
            box-sizing: initial;
        }
        &.with-total-panel {
            &.no-buttons {
                height: calc(100% - 143px);
            }
            overflow-y: auto;
            height: calc(100% - 219px);
            box-sizing: initial;
        }
    }
}

.sm-primary-button-label {
    color:rgba(255, 255, 255, 1);
    font-weight: 500;
    &.text-normal {
        text-transform: none;
    }
}

.card-header {
    display: flex;
    align-items: flex-end;
    .title {
        flex: 1;
        color: #0f4a53;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 13px;
    }
}

.page-content {
    padding: 10px;
}

.sm-switch-checked {
    color: #0e575e!important;
}

.sm-switch-track {
    background-color: #555!important;
}

.switch-label {
    margin-right: 10px;
}

.sm-progress {
    z-index: 200;
    position: absolute!important;
    width: 100%;
}

.empty-grid {
    text-align: center;
    padding: 40px;
    color: #888;
}

.sm-badge {
    right: -7px!important;
    top: 13px!important;
    border: 2px solid #fff;
    padding: 0 4px!important;
    color: #fff!important;
    background-color: #DC004E!important;
}

input.sm-outlined-input-small,
.sm-outlined-input-small {
    &.no-right-pad {
        input {
            padding-right: 0px;
        }
    }
    input {
        padding: 10.5px 14px;
    }
    .MuiInputLabel-outlined {
        transform: translate(14px, 12px) scale(1);
        font-size: 0.9rem;
    }
}

.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled,
.MuiInputBase-root.Mui-disabled.MuiInputBase-formControl.MuiInputBase-adornedEnd {
    background-color: #f5f5f5;
}

.sm-icon-button-small {
    padding: 8px!important;
}

.contact-avatar {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    font-size: 0.9em;
}

.check-wrap {
  margin-bottom: 10px;
  .check-row {
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
      color: #a8a8a8;
      font-size: 18px;
    }
    .check-text {
      font-size: 12px;
      color: #a8a8a8;
    }
    &.valid {
      svg {
        color: #8ABA04;
      }
      .check-text {
        color: #8ABA04;
        font-weight: 500;
      }
    }
  }
}

// @media (min-width: $screen-md-min) {
//     .with-right-panel {
//         .content {
//             width: calc(100% - 350px);
//         }
//         .right-panel {
//             width: 350px;
//         }
//     }
// }

// @media (min-width: $screen-lg-min) {
//     .with-right-panel {
//         .content {
//             width: calc(100% - 400px);
//         }
//         .right-panel {
//             width: 400px;
//         }
//     }
// }
