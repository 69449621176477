.sidebar {
    max-height: 100%;
    height: 100%;
    transition-property: top, bottom, width;
    transition-duration: 0.2s, 0.2s, 0.35s;
    transition-timing-function: linear, linear, ease;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 260px;
    display: block;
    z-index: 100;
    color: #fff;
    font-weight: 200;
    // overflow-x: hidden;
    background-color: #0d5869;
    .ps__rail-y {
        width: auto;
        &:hover, &.ps--clicking {
            > .ps__thumb-y {
                background-color: #eee;
                opacity: 0.9;
                width: 8px;
            }
        }        
    }    
    .sidebar-wrapper {
        overflow: auto;
        position: relative;
        z-index: 101;
        width: 260px;
        height: calc(100% - 100px);
        padding-bottom: 30px;

        transition-property: top, bottom, width;
        transition-duration: 0.2s, 0.2s, 0.35s;
        transition-timing-function: linear, linear, ease;
        -webkit-overflow-scrolling: touch;        
    }
    .logo {
        padding: 20px 0px 40px 0px;
        margin: 0;        
        position: relative;
        z-index: 101;
        display: block;        
        a.logo-mini {
            float: left;
            width: 30px;
            text-align: center;
            margin-left: 25px;
            margin-right: 15px;
            position: relative;
            padding-top:3px;
        }
        a.logo-normal {
            display: block;
            opacity: 1;
            transform: translate3d(0px, 0, 0);  
            img {
                width: 90px;
            }          
        }
        .simple-text {
            text-transform: uppercase;
            padding: 5px 0px;
            display: inline-block;
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
            white-space: nowrap;
            color: #ffffff;            
        }
        .logo-img {
            width: 30px;
            display: inline-block;
            max-height: 30px;
            img {
                width: 30px;
            }
        }
    }
    a, .logo-normal, .caret {
        transition: all 150ms ease-in; 
    }
    span.sidebar-text, div.nav-category, .sidebar-normal  {
        transition: all 150ms linear; 
    }
}

.nav {
    display: block!important;
    .collapse:not(.show) {
        display: none!important;
    }
    a, div.nav-category, div.clickable {
        outline: none;
        cursor: pointer;
        padding-left: 10px;
        padding-right: 10px;
        margin: 5px 15px 0px;
        border-radius: 4px;
        color: #ffffff;
        opacity: 0.86;
        position: relative;
        display: block;
        padding: 10px 11px;
        &.seperator {
            margin-top: 40px;
        }        
        &.active {                
            color: #ffffff;
            opacity: 1;
            background: rgba(255, 255, 255, 0.23);
            &:hover {
                background: rgba(255, 255, 255, 0.23);
            }                           
        }
        &:hover {
            background: rgba(255, 255, 255, 0.13);
            opacity: 1;
            color: #ffffff;
        }        
        .icon {
            font-size: 28px;
            float: left;
            margin-right: 15px;
            line-height: 30px;
            width: 30px;
            text-align: center;
            &.small {
                font-size: 27px;
                margin-top:1px;
            }
        }
        .secondary-icon {
            position: absolute;
            left: 23px;
            color: #ffffff;
            top: 23px;
            font-size: 0.75rem;            
        }
        .secondary-icon.small {            
            font-size: 0.6rem;            
        }        
        .sidebar-normal {
            margin: 0;
            position: relative;
            transform: translate3d(0px, 0, 0);
            opacity: 1;
            white-space: nowrap;
            display: block;
            font-size: 0.9rem;
            line-height: 1.9rem;;
        }
        .sidebar-mini {
            float: left;
            width: 30px;
            text-align: center;
            margin-right: 15px;
            letter-spacing: 1px;
            margin-top: 3px;
        }
    }

    span.sidebar-text {
        margin: 0;
        line-height: 30px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        white-space: nowrap;
        position: relative;
        color: #ffffff;
        transform: translate3d(0px, 0, 0);
        display: block;
        height: auto;
        opacity: 1;
        .caret {
            top: 12px;
            position: absolute;
            right: 0px;
        }
    }
    .caret {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 2px;
        vertical-align: middle;
        border-top: 4px dashed;
        border-top: 4px solid\9;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
    }

    .bottom {
        position: absolute;
        bottom: 40px;
        width: 100%;        
    }

    .contact-avatar {        
        width: 1.8rem;
        height: 1.8rem;        
        font-size: 0.7em;
        float: left;
        margin-right: 15px;
        line-height: 30px;
        width: 30px;
        text-align: center;        
    }
}

.sidebar-mini {
    &.main-container {
        .main-panel {
            width: calc(100% - 80px);
        }        
    }
    .sidebar {
        display: block;
        font-weight: 200;
        z-index: 1000;
        width: 80px;
        &.collapsed {        
            width: 80px!important;
            .logo a.logo-normal {
                opacity: 0!important;
                transform: translate3d(-25px, 0, 0)!important;
            }
            .sidebar-wrapper {
                width: 80px!important;
                .nav span.sidebar-text,
                .nav .sidebar-normal {
                    transform: translate3d(-25px, 0, 0)!important;
                    opacity: 0!important;
                }               
            }            
        }
        &:hover {
            width: 260px;
            .logo a.logo-normal {
                opacity: 1;
                transform: translate3d(0px, 0, 0);            
            }
            .sidebar-wrapper {
                width: 260px;
                .nav span.sidebar-text,
                .nav .sidebar-normal {
                    transform: translate3d(0px, 0, 0);
                    opacity: 1;                     
                }               
            }
        }
        .logo a.logo-normal {
            opacity: 0;
            transform: translate3d(-25px, 0, 0);            
        }
        .sidebar-wrapper {
            width: 80px;
            .nav span.sidebar-text,
            .nav .sidebar-normal {
                transform: translate3d(-25px, 0, 0);
                opacity: 0;
            }
        }        
    }
}

.popover-logout {    
    max-width: 400px;
    min-width: 300px;
    .pi-wrap {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid #dadce0;
        .contact-avatar {
            margin-bottom: 10px;
        }
        .pi-email, .pi-roles {
            font-size: 0.9em;
            color: #5f6368;
        }
        .pi-roles {
            color: #95989c;
            text-align: center;
            margin-top: 10px;
            padding-left: 10px;
            padding-right: 10px;
        }
        .pi-name {
            font-weight: 500;
        }
    }
    .pi-actions {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}