.g-column {
    display:flex;
    justify-content:center;
    padding-top:5px;
    padding-bottom:0px;
    font-weight:500;
}

.g-row-container {
    margin-top: 10px;
}

.g-row {
    border:1px solid #bebebe;
    border-radius:7px;
    padding-top:15px;
    padding-bottom:15px;
    background-color:#fff;
    z-index:10;
}

.g-row.g-shadow {
    box-shadow: 0px 10px 10px -5px rgba(199,197,199,1);
}

.g-cell {
    display: flex;
    justify-content:center;
    align-items:center;
    font-size:12px;
    text-align:center;
}

.rb-wrap {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border: 2px solid #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.rb-content {
    border-radius: 50%;
    background-color:#46bdc8;
    width: 20px;
    height: 20px;
}

.g-cell.highligted {
    color:#46bdc8;
    font-weight: bold;
}

.g-cell.g-icon span {
    font-size:32px;
    cursor: pointer;
    display: none;
}

.g-icon:hover{
    color:#555;
}

.g-expander {
    border:1px solid #bebebe;
    border-bottom-right-radius:7px;
    border-bottom-left-radius:7px;
    margin-top:-5px!important;
    font-size:12px;
    transition: max-height 0.3s;
    overflow:hidden;
    width:100%;
}

.g-expander-content {
    padding:20px;
    padding-top:40px;
}

.g-expander.expanded {
    max-height:1000px;
}

.g-expander.collapsed {
    max-height:0;
}

.g-expander-row {
    margin-bottom:10px!important;
    border-bottom: 1px solid #bebebe;
    padding-bottom:7px;
}

.g-expander-row.g-expander-row-total{
    color:#46bdc8;
    margin-top:20px!important;
    font-weight:bold;
    border-bottom-width: 0px;
    padding-bottom:0px;
}
