
.k-notification-container {
  margin: 6px;
}

.k-dropdown-wrap > .k-select > .k-i-filter {
  color: #0D5869;
  display: none;
}

//.k-grid {
//  height: 92vh;
//}
